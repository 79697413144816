import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import UserList from "./components/UserList"; // Import UserList
import Login from "./components/Login"; // Import your Login component
import ChangePassword from "./components/ChangePassword"; // If you have this component

const App = () => {
  return (
    <Router>
      <div className="container mx-auto">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/users" element={<UserList />} /> {/* Route for UserList */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
