import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD6NqPPQutyvNiLjEJTN-y6eKAY_Gd_r10",
  authDomain: "cyberstack-2ed8c.firebaseapp.com",
  projectId: "cyberstack-2ed8c",
  storageBucket: "cyberstack-2ed8c.appspot.com",
  messagingSenderId: "9374400707",
  appId: "1:9374400707:web:1d68de286d39ad402ca276",
  measurementId: "G-4K9XCH4M91"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
