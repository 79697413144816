import React, { useEffect, useState } from "react";
import { db } from "../firebase"; // Ensure this import is correct
import { collection, getDocs } from "firebase/firestore";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, "users"); // Replace with your collection name
        const userDocs = await getDocs(usersCollection);
        const userList = userDocs.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(userList);
      } catch (error) {
        console.error("Error fetching users: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  if (loading) return <div>Loading users...</div>;

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">User List</h2>
      <ul>
        {users.map((user) => (
          <li key={user.id} className="mb-2">
            {user.email} - {user.createdAt?.toDate().toLocaleDateString()} {/* Adjust based on your user fields */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserList;
