import React, { useState } from "react";
import { auth } from "../firebase";
import { updatePassword } from "firebase/auth";

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;

    if (user) {
      try {
        await updatePassword(user, newPassword);
        setSuccess("Password updated successfully!");
        setNewPassword("");
      } catch (error) {
        setError(error.message);
      }
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-96">
        <h2 className="text-2xl font-bold text-center mb-6">Change Password</h2>
        <form onSubmit={handleChangePassword}>
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">New Password</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
              placeholder="Enter new password"
            />
          </div>

          <button
            type="submit"
            className="w-full py-2 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-700 transition duration-200"
          >
            Change Password
          </button>
        </form>
        {error && (
          <div className="mt-4 p-3 text-center text-red-600 bg-red-100 rounded-lg">
            {error}
          </div>
        )}
        {success && (
          <div className="mt-4 p-3 text-center text-green-600 bg-green-100 rounded-lg">
            {success}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChangePassword;
